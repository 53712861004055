import Navbar from "../../components/Navbar";
import NavBarMobile from "../../components/NavBarMobile";
import CmsPage from "../../components/CmsPage";

import { loadPage, loadSiteMap } from '../../lib/api-lib'

const paths = [];

function Page({ structure }) {
  return (
    <>
      {typeof structure !== 'undefined' ? 
      <>
        <Navbar navigation={structure.navigation ?? ''} />
        <NavBarMobile navigation={structure.navigation ?? ''} />
        <CmsPage structure={structure} />
      </>
      : ''}
    </>
  )
}

export async function getStaticProps(context) {
  var pageSlugs = context.params.slug
  let queryParams = '';
  if(!isNaN(pageSlugs[pageSlugs.length-1])) {
    let id = pageSlugs.pop()
    queryParams = '?id=' + id;
  }
  const data = await loadPage(pageSlugs.join('/')+queryParams) || false;
  if(data == false) {
    return { 
      notFound: true 
    };
  } else {
    return {
      props: {
        structure: data,
      },
      revalidate: 10,
    };
  }
}

export async function getStaticPaths() {
  const json = await loadSiteMap()
  json.map(function(link) {
    recursiveStaticPathGeneration(link);
  })
  
  return {
    paths,
    fallback: true,
  }
}

function recursiveStaticPathGeneration(link) {
  if(typeof link.path != 'undefined') {
    paths.push('/page'+link.path)
  }
  if (typeof link.navigation !== 'undefined' && link.navigation.length > 0) {
    link.navigation.map(function (slink) {
      recursiveStaticPathGeneration(slink);
    });
  }
}

export default Page